import { hide_password, inputCancelIcon, searchIcon, show_password } from 'assets';
import { InputHTMLAttributes, ReactNode, forwardRef, useState } from 'react';

export interface TextInputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  passwordInput?: boolean;
  prefixLabel?: string;
  suffixLabel?: string;
  name?: string
  setValue?: (e?: any) => void;
  required?: boolean;
  label?: string;
  errorMessage?: any;
  icon?: ReactNode;
  iconClick?: () => void;
  prefix?: string;
  onClick?: (e: any) => void;
  rows?: number;
  inputWrapperClass?: string;
  isRequiredIconPosition?: 'left' | 'right';
  inputClass?: string
  labelClass?: string
  allowCancel?: boolean
  disabled?: boolean
  value?: string | number
}

export const TextInput = forwardRef(
  (
    {
      value,
      onClick,
      setValue,
      label,
      rows,
      name,
      prefixLabel,
      suffixLabel,
      iconClick,
      passwordInput,
      type,
      required,
      errorMessage,
      icon,
      className,
      isRequiredIconPosition = 'right',
      inputWrapperClass,
      inputClass,
      labelClass,
      allowCancel = true,
      disabled,
      ...rest
    }: TextInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [iconSwitch, setSwitch] = useState(false);
    // const [valueState, setValueState] = useState(value);
    // const [searchValueState, setSearchValueState] = useState(value);

    const switchIcon = () => setSwitch(!iconSwitch);

    return (
      <div className={`${className}`}>
        {label && (
          <div className="w-full flex items-center mb-1">
            {required && isRequiredIconPosition === 'left' && (
              <span className="text-col_ff0000 mr-[3px]"> * </span>
            )}
            {prefixLabel && <img src={prefixLabel} alt="" className="ml-2" />}
            <label
              className={`text-[0.75rem] md:text-[0.75rem] capitalize font-[400] text-col_525252 ${labelClass}`}
            >
              {label}
            </label>
            {suffixLabel && <img src={suffixLabel} alt="" className="ml-2" />}
            {required && isRequiredIconPosition !== 'left' && (
              <span className="text-col_ff0000 ml-[3px]"> * </span>
            )}
          </div>
        )}

        <main
          className={`w-full rounded-[0.5rem] py-1 px-1 flex items-center justify-between gap-[0.5rem] ${
            inputWrapperClass ? inputWrapperClass : 'bg-col_f8f8f8'
          }`}
        >
          {type === 'textarea' ? (
            <textarea
              {...rest}
              value={value}
              onChange={(e: any) => setValue && setValue(e.target.value)}
              rows={rows ? rows : 5}
              disabled={disabled}
              className={`w-full bg-col_f8f8f8 placeholder-[#BFBEBD] outline-0 px-3 text-[0.875rem] leading-normal font-normal `}
            />
          ) : type === 'search' ? (
            <div className="w-full flex">
              <img src={searchIcon} alt="search icon" className="" />
              <input
                {...rest}
                type={'text'}
                ref={ref}
                // name={name}
                onChange={(e: any) => {
                  setValue && setValue(e.target.value)
                  //setSearchValueState(e.target.value)
                }}
                value={value}
                autoComplete="off"
                disabled={disabled}
                className={`w-full placeholder-[#BFBEBD] placeholder:text-opacity-[65%] bg-transparent outline-0 px-3 text-[0.875rem] leading-normal font-normal ${inputClass}`}
              />
               {/* {
                searchValueState === "" ? <></> : 
                <img onClick={() => setSearchValueState("")}  src={inputCancelIcon} alt="cancel icon" className='z-9999 cursor-pointer w-[1rem]' />
              } */}
            </div>
          ) : (
            <>
            <input
              {...rest}
              type={
                type ? type : passwordInput && !iconSwitch ? 'password' : 'text'
              }
              ref={ref}
              name={name}
              onChange={(e: any) => {
                setValue && setValue(e.target.value)
                // setValueState(e.target.value)
              }}
              value={value}
              autoComplete="off"
              disabled={disabled}
              className={`w-full placeholder-[#BFBEBD] placeholder:text-opacity-[65%] bg-transparent py-1 outline-0 px-3 text-[0.875rem] leading-normal font-normal`}
            />
            {allowCancel && value && !disabled ?
              // valueState === "" ? <></> : 
              <img onClick={() => {
                // setValueState("")
                setValue && setValue("")
              }} src={inputCancelIcon} alt="cancel icon" className='z-9999 cursor-pointer w-[1rem]' 
              /> : null
            } 
            </>
          )}

          {passwordInput && (
            <div
              className="w-[3rem] h-full bg-transparent flex justify-center items-center"
              onClick={() => switchIcon()}
            >
              {
                <img
                  src={iconSwitch ? hide_password : show_password}
                  alt=""
                  className="cursor-pointer"
                />
              }
              {/* <span className='cursor-pointer'>{iconSwitch ? "H" : "S"}</span> */}
            </div>
          )}
        </main>
        {errorMessage && (
          <div className="w-full ">
            <span className="text-col_ff0000 text-[12px] ">{errorMessage}</span>
          </div>
        )}
      </div>
    );
  },
);